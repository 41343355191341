import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import clsx from "clsx";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import BtnMenu from '../BtnMenu/BtnMenu';

export default function Navbar() {
    const [NavbarOpened, setOpen] = useState(false);
    const location = useLocation();

    const toggleNavbar = () => {
        setOpen(!NavbarOpened);
    }

    useEffect(() => { setOpen(false) }, [location]);

    return (
        <div className={clsx('Navbar', !NavbarOpened && 'closed')}>

            <div className="topBar">
                <div className="logoBox">
                    <div className="logo">M.SC. ZIMMERER</div>
                </div>

                <BtnMenu
                    toggleNavbar={toggleNavbar}
                    NavbarOpened={NavbarOpened}
                />
            </div>

            <div className="overlay" onClick={toggleNavbar} />

            <div className="itemsBox">

                <Link to="/" className="navItem">HOME</Link>

                <a
                    href="#adress"
                    className="navItem"
                    rel="noreferrer"
                >
                    IHR WEG ZU UNS
                </a>

                <Link to="imprint" className="navItem">IMPRESSUM</Link>
            </div>

        </div>
    )
}