import M from "./ContactBtn.module.scss";
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import clsx from "clsx";
import { Button } from '@mui/material';
import { useState } from "react";

export default function ContactBtn() {
    const [showed, setShowed] = useState(false);

    return (
        <div
            className={clsx(
                M.ContactBtn,
                M.onSite,
                showed && M.showed
            )}
        >
            <Button>
                <a href="mailto:info@zimmerer-immobilien.de"><MailIcon /></a>
            </Button>
            <Button>
                <a href="https://wa.me/4915229424319?text=Ich%20wollte%20wegen%20der%20Wohnung%20nachfragen"><WhatsAppIcon /></a>
            </Button>
            <Button>
                <a href="tel:+4915229424319"><CallIcon /></a>
            </Button>
            <Button className={showed && M.toggler} onClick={() => setShowed(!showed)}>
                <ArrowRightIcon />
            </Button>
        </div>
    )
}

