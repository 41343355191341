import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Navbar/Navbar';
import Home from './Home/Home';
import Footer from './Footer/Footer';
import Imprint from './Imprint';

function App() {
  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="imprint" element={<Imprint />} />
        </Routes>

        <Footer />

        <Navbar />

      </BrowserRouter>
    </div>
  );
}

export default App;
