import Header from "./Header";
import ContactBtn from "./ContactBtn/ContactBtn";
import ObjectSurveillance from "./ObjectSurveillance/ObjectSurveillance";
import Auspruch from "./Auspruch/Auspruch";
import Challenge from "./Challenge/Challenge";

export default function Home() {
    return (
        <div className="section">
            <Header />
            <ContactBtn />
            <ObjectSurveillance />
            <Auspruch />
            <Challenge />
        </div>
    )
}