import './Auspruch.scss';
import professional from '../../imgs/professional.jpg';

export default function Auspruch() {
    return (
        <div className='Auspruch'>
            <div className='section'>
                <img className='bgImg' src={professional} alt="" />

                <div className='text'>
                    Höchste <b>Professionalität</b>
                    <br />
                    auf allen Ebenen
                    <br />
                    ist dabei unser Anspruch
                </div>

            </div>
        </div>
    )
}