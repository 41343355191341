import './Challenge.scss';
import cloud21 from '../../imgs/cloud21.svg';
import Challenge1 from '../../imgs/Challenge1.svg';
import building3 from '../../imgs/building3.svg';
import Stack from '@mui/material/Stack';

export default function Challenge() {
    return (
        <div className='Challenge'>
            <div className='section'>

                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >

                    <div className='bgImgBox'>
                        <img className='bgImg img1' src={cloud21} alt="" />
                        <img className='bgImg img2' src={cloud21} alt="" />
                        <img className='bgImg img3' src={Challenge1} alt="" />
                        <img className='bgImg img4' src={building3} alt="" />
                    </div>

                    <div className='text'>
                        <b>Als unermüdliche Problemlöser</b> nehmen wir jede Herausforderung an und finden immer den <b>direkten Weg zum Ziel.</b>
                    </div>
                    
                </Stack>

            </div>
        </div>
    )
}