import M from './Header.module.scss';
import peter from "../imgs/Peter.png";

export default function Header() {
    return (
        <div className={M.Header}>
            <div className={M.bgImg}>
                <img src={peter} alt="" />
            </div>

            <div className={M.text}>
                <div className={M.name}>
                    <div className={M.myName}>
                        M.SC. PETER ZIMMERER
                    </div>

                    <div className={M.subName}>
                        <div className={M.subName1}>Immobilien</div>
                        <div className={M.subName2}>
                            <span className={M.beratungs}>Beratungs-</span>
                            <span className={M.und}>und</span>
                            <span className={M.dienstleistungen}>Dienstleistungen</span>
                        </div>
                    </div>
                </div>

                <div className={M.ourApproach}>
                    Konsequentes Handeln im Interesse der Auftraggeber und Eigentümer
                </div>
            </div>
        </div>
    )
}