import "./ObjectSurveillance.scss";

import building1 from "../../imgs/building1.svg";
import building2 from "../../imgs/building2.svg";
import objectSurveillance from "../../imgs/objectSurveillance.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Stack from '@mui/material/Stack';

export default function ObjectSurveillance() {
    return (
        <div className="ObjectSurveillance">
            <div className="section">

                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <div className="bgImgBox">
                        <img className="bgImg img1" src={building1} alt="" />
                        <img className="bgImg img2" src={building2} alt="" />
                        <img className="bgImg img3" src={objectSurveillance} alt="" />
                    </div>

                    <div className="text">
                        <div className="title">Wir bieten <br /> umfassende Erfahrung an</div>

                        <div className="paragraph">
                            <Carousel
                                infiniteLoop={true}
                                showArrows={false}
                                showIndicators={false}
                                showStatus={false}
                                showThumbs={false}
                                autoPlay={true}
                            >
                                <div className="p p1">in der Objektüberwachung</div>
                                <div className="p p2">in der Projektsteuerung</div>
                                <div className="p p3">im Projektcontrolling</div>
                                <div className="p p4">im Fertigstellungsmanagement</div>
                                <div className="p p4">in der Immobilienverwaltung</div>
                                <div className="p p4">in der Projektentwicklung</div>
                            </Carousel>
                        </div>

                    </div>

                </Stack>

            </div>
        </div>
    )
}