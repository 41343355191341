import M from './Footer.module.scss';
import { Link } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

export default function Footer() {

    return (
        <div className={M.Footer}>
            <div className={M.container}>

                <div className={M.extra}>
                    <p>
                        <a href="mailto:info@zimmerer-immobilien.de" target="_blank" rel="noreferrer">
                            <MailIcon /> info@zimmerer-immobilien.de
                        </a>
                    </p>
                    <p>
                        <a href="https://wa.me/4915229424319?" target="_blank" rel="noreferrer">
                            <WhatsAppIcon /> +4915229424319
                        </a>
                    </p>
                    <p>
                        <a href="tel:+4915229424319" target="_self">
                            <CallIcon /> +4915229424319
                        </a>
                    </p>
                </div>

                <div className={M.gridTplCol200} id="adress">
                    <div>
                        <span className={M.myName}>M. SC. PETER ZIMMERER</span>
                        <br />
                        Immobilien Beratungs- und Dienstleistungen
                    </div>

                    <div>
                        <a href="https://goo.gl/maps/Y2nyuzHGhSWxTE6K9">
                            Edisonweg 1 <br /> 82140 Olching
                        </a>
                    </div>
                    <iframe title='address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2658.5825897599925!2d11.316232615530774!3d48.214655387529916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e7ef63f941ca9%3A0x3bae9d4ee732fd34!2sEdisonweg%201%2C%2082140%20Olching!5e0!3m2!1sen!2sde!4v1667581336398!5m2!1sen!2sde" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <p>
                    <Link to="imprint" >Impressum</Link>
                </p>
            </div>
        </div>
    )
}